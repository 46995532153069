export default {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          "Click to tune": "Clique para personalizar",
          "or drag to move": "ou arraste para mover",
        },
      },
      inlineToolbar: {
        converter: {
          "Convert to": "Converter para",
        },
      },
      toolbar: {
        toolbox: {
          Add: "Adicionar",
        },
      },
      popover: {
        Filter: "Filtrar",
        "Nothing found": "Nada encontrado",
      },
    },

    toolNames: {
      Text: "Texto",
      Heading: "Título",
      List: "Lista",
      Checklist: "Checklist",
      Delimiter: "Separador",
      Table: "Tabela",
      Marker: "Marcador",
      Bold: "Negrito",
      Italic: "Itálico",
      Underline: "Sublinhado",
      Maker: "Destacar",
    },

    tools: {
      header: {
        "Heading 1": "Título 1",
        "Heading 2": "Título 2",
        "Heading 3": "Título 3",
      },
      table: {
        "Add column to right": "Adicionar coluna a direita",
        "Add column to left": "Adicionar coluna a esquerda",
        "Delete column": "Excluir coluna",
        "Add row above": "Adicionar linha acima",
        "Add row below": "Adicionar linha abaixo",
        "Delete row": "Excluir linha",
        "With headings": "Com cabeçalho",
        "Without headings": "Sem cabeçalho",
      },
      list: {
        Unordered: "Não ordenada",
        Ordered: "Ordenada",
      },
    },

    blockTunes: {
      delete: {
        Delete: "Excluir",
      },
      moveUp: {
        "Move up": "Mover para cima",
      },
      moveDown: {
        "Move down": "Mover para baixo",
      },
    },
  },
};
